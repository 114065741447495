import get from 'lodash.get';
import type { LinksToScreenData } from './index.server.ts';
import logger from '../logger/index.ts';
import type { FeedEntry, UIComponent, Screen } from './index.ts';
import { nanoid } from 'nanoid';

/**
 * handler to convert the string received in item_limit to a number.
 * return undefined as a fallback to be handled gracefully by the slice method.
 * @param limit component item_limit.
 * @returns component limit as typeof number OR undefined.
 */
export function getLimit(
  limit: string | number | undefined
): number | undefined {
  try {
    if (limit === '') return undefined;
    if (isNaN(Number(limit))) return undefined;

    return Number(limit);
  } catch (error) {
    return undefined;
  }
}

export function getPayloadImgSrc(
  assets: any,
  payload: any
): string | undefined {
  try {
    const imgKey: string | undefined = assets?.image_key;

    const imageSwitch: boolean | undefined = assets?.image_switch;

    if (typeof imageSwitch !== 'undefined' && imageSwitch === false)
      return undefined;

    const mediaItem = payload?.media_group[0].media_item.find(
      (mediaItem: any) => {
        return mediaItem.key === imgKey;
      }
    );

    return mediaItem?.src || payload?.media_group[0]?.media_item[0]?.src;
  } catch (error) {
    return undefined;
  }
}

export function getEpgImage(
  program: any,
  imageKey: string | undefined
): string {
  try {
    if (!program) throw new Error('No program');

    if (!Array.isArray(program?.media_group)) throw new Error('No media group');

    const mediaItem = program?.media_group[0].media_item.find(
      (mediaItem: any) => {
        return mediaItem.key === imageKey;
      }
    );

    return mediaItem?.src || program?.media_group[0]?.media_item[0]?.src || '';
  } catch (error: any) {
    return '';
  }
}

/**
 * @param cellConfig cell styles configurations
 * @param assetKey asset key name
 * @param switchName switch key name, defaults to ''
 * @returns
 * if switch = undefined return asset | undefined
 * if switch = false return undefined
 * if switch = true return asset
 * if asset = undefined return undefined
 */
export function getCellAssetSrc(
  cellConfig: any,
  assetKey: string,
  switchName: string = '',
  configurationKey: string = 'assets'
): string | undefined {
  try {
    if (switchName !== '') {
      const cellAssetSwitch: boolean | undefined =
        cellConfig?.configuration?.[`${configurationKey}`]?.[`${switchName}`];

      if (typeof cellAssetSwitch !== 'undefined') {
        if (cellAssetSwitch === false) throw Error('switch_off');
      }
    }

    const cellAsset: string | undefined =
      cellConfig?.configuration?.[`${configurationKey}`]?.[assetKey];

    return cellAsset || undefined;
  } catch (error: any) {
    return undefined;
  }
}

/**
 * Get top/right/bottom/left position values
 * @param actionPosition configuration.assets.action_position value
 * @param cssProperty 4 options: 'top', 'right', 'bottom', 'left'
 * @returns 'auto' or '0'
 */
export function getAssetPosition(
  assetPosition: any,
  cssProperty: string
): string {
  try {
    validateValues();

    const res = assetPosition.includes(cssProperty);

    return res ? '0' : 'auto';

    function validateValues(): void | Error {
      const assetPositions: string[] = assetPosition?.split('_');

      const isValidActionPosition: boolean =
        Array.isArray(assetPositions) && assetPositions?.length === 2;

      if (!isValidActionPosition)
        throw Error('invalid cell_styles action_position');

      let isAcceptedY = false;
      let isAcceptedX = false;
      ['top', 'right', 'bottom', 'left'].forEach((value: string) => {
        if (isAcceptedY && isAcceptedX) return;
        if (assetPositions[0] === value) isAcceptedY = true;
        if (assetPositions[1] === value) isAcceptedX = true;
      });
      if (!isAcceptedY) throw Error(`invalid Y position value`);
      if (!isAcceptedX) throw Error(`invalid X position value`);
    }
  } catch (error: any) {
    return 'auto';
  }
}

export function getScreenRelativePath(
  screen: Screen | LinksToScreenData | undefined
): string | undefined {
  if (!screen) return undefined;

  const customPathname = get(screen, 'general.pathname');

  if (screen.type === 'quick-brick-login-flow') return '/login';
  if (screen.type === 'web-storefront') return '/payment';

  return customPathname || screen.id;
}

/**
 * get home screen details
 * @param layout
 * @returns on success, returns the home screen id and index
 */
export function getHomeScreenDetails(screens: Screen[]): {
  success: boolean;
  homeScreenId?: string;
  homeScreenIndex?: number;
} {
  try {
    const indexFound = screens.findIndex((screen) => screen.home);

    if (indexFound === null) throw Error('did not find home screen in layout');

    return {
      success: true,
      homeScreenId: screens[indexFound].id,
      homeScreenIndex: indexFound,
    };
  } catch (error) {
    return {
      success: false,
    };
  }
}

/**
 * Get the first empty group component in a group component
 * @param groupUiComponent group-qb component
 * @returns empty group component OR undefined
 */
export function getEmptyGroupComponent(
  groupUiComponent: UIComponent
): UIComponent | undefined {
  return groupUiComponent?.ui_components?.find(
    (uiComponent: UIComponent) =>
      uiComponent?.component_type === 'empty_group_component'
  );
}

/**
 * Get the feed entries from the state if it has more entries than the entries prop.
 * Get the entries prop if isClient is true.
 * @param feedEntries
 * @param entries
 * @param isClient
 * @returns
 */
export function getDynamicFeedEntries({
  feedEntries,
  entries,
  isClient,
}: {
  feedEntries: any;
  entries: any;
  isClient: boolean;
}): FeedEntry[] | [] {
  return entries && !isClient && feedEntries?.length > entries?.length
    ? feedEntries
    : entries;
}

export type ResponsiveCellType =
  | 'mobile'
  | 'tablet'
  | 'desktop'
  | 'large-desktop';

/**
 * Get the cell plugin configuration id for the SEO breakpoint
 * @param uiComponent - the UIComponent object
 * @returns the configuration id for the SEO breakpoint
 */
export function getSeoCellId(uiComponent: UIComponent): string {
  const cellBreakpointType =
    seoSelectedBreakpoint === 'large-desktop'
      ? 'large_desktop'
      : seoSelectedBreakpoint;
  const cellId: string | undefined =
    uiComponent?.styles?.[`${cellBreakpointType}_cell_plugin_configuration_id`];
  return cellId || '';
}

/**
 * Default mobile cell id for the responsive breakpoints.
 * Used as a fallback when the configuration id is not set for the mobile breakpoint.
 */
export const defaultMobileCell1Id = 'default-mobile-cell-1';

/**
 * Default mobile cell info id for the responsive breakpoints.
 * Used as a fallback when the configuration id is not set for the mobile breakpoint
 * for group info v2 component.
 */
export const defaultMobileCellInfoId = 'default-mobile-cell-info';

/**
 * Get the cell plugin configuration ids for the responsive breakpoints
 * If the configuration id is not set for a breakpoint, it will fallback to the previous breakpoint.
 * @param uiComponent - the UIComponent object
 * @returns object with the configuration ids for the breakpoints
 */
export function getCellPluginConfigurationIds(uiComponent: UIComponent) {
  let {
    mobileCellId,
    tabletCellId,
    desktopCellId,
    largeDesktopCellId,
  }: ResponsiveCellIds = getUiComponentResponsiveCellIds(uiComponent);

  if (!mobileCellId) {
    const isGroupInfoV2Component =
      uiComponent?.component_type === 'group_info_v2';

    mobileCellId = isGroupInfoV2Component
      ? defaultMobileCellInfoId
      : defaultMobileCell1Id;
  }

  if (!tabletCellId) {
    tabletCellId = mobileCellId;
  }
  if (!desktopCellId) {
    desktopCellId = tabletCellId || mobileCellId;
  }
  if (!largeDesktopCellId) {
    largeDesktopCellId = desktopCellId || tabletCellId || mobileCellId;
  }

  return {
    mobileCellId,
    tabletCellId,
    desktopCellId,
    largeDesktopCellId,
  };
}

/**
 * Get the cell plugin configuration ids including the responsive breakpoints.
 * @param uiComponent - the UIComponent object.
 * @returns array with the ids and the breakpoint type.
 */
export function getResponsiveCellPluginConfigurationIds(
  uiComponent: UIComponent
): [string, ResponsiveCellType][] {
  if (!uiComponent) return [];

  const { mobileCellId, tabletCellId, desktopCellId, largeDesktopCellId } =
    getCellPluginConfigurationIds(uiComponent);

  const cellIds: [string, ResponsiveCellType][] = [];

  cellIds.push([mobileCellId, 'mobile']);
  cellIds.push([tabletCellId, 'tablet']);
  cellIds.push([desktopCellId, 'desktop']);
  cellIds.push([largeDesktopCellId, 'large-desktop']);

  return cellIds;
}

/**
 * Responsive breakpoints source of truth for the application.
 */
export const responsiveBreakpoints: {
  tablet: number;
  desktop: number;
  'large-desktop': number;
} = {
  tablet: 640,
  desktop: 1024,
  'large-desktop': 1344,
};

/**
 * SEO selected breakpoint.
 * Will be configurable by the customer in the future
 */
export const seoSelectedBreakpoint: ResponsiveCellType = 'mobile';

/**
 * Get media query string for the breakpoint based on the responsiveBreakpoints object
 * @param breakpoint - mobile | tablet | desktop | large-desktop
 * @returns media query string for the breakpoint
 */
export function getBreakpointMediaQuery(
  breakpoint: ResponsiveCellType
): string {
  if (breakpoint === 'mobile')
    return `@media (max-width: ${responsiveBreakpoints.tablet}px)`;

  if (breakpoint === 'tablet')
    return `@media (max-width: ${responsiveBreakpoints.desktop}px) and (min-width: ${responsiveBreakpoints.tablet}px)`;

  if (breakpoint === 'desktop')
    return `@media (max-width: ${responsiveBreakpoints['large-desktop']}px) and (min-width: ${responsiveBreakpoints.desktop}px)`;

  if (breakpoint === 'large-desktop')
    return `@media (min-width: ${responsiveBreakpoints['large-desktop']}px)`;

  throw new Error(
    'getBreakpointMediaQuery: Breakpoint must be one of mobile, tablet, desktop, large-desktop'
  );
}

/**
 * Get the duration of the feed entry in HH:MM:SS format
 * @param entry - feed entry
 * @returns - duration as a string or undefined
 */
export function getDuration(entry: FeedEntry): string | undefined {
  try {
    const seconds = entry.extensions?.duration;
    const hours = Math.floor(seconds / 3600);

    if (hours === 0) {
      return new Date(seconds * 1000).toISOString().slice(14, 19);
    }

    return new Date(seconds * 1000).toISOString().slice(11, 19);
  } catch (error: any) {
    return undefined;
  }
}

export const responsiveBreakpointNames: ResponsiveBreakpointNames[] = [
  'mobile',
  'tablet',
  'desktop',
  'large-desktop',
];

export type ResponsiveBreakpointNames =
  | 'mobile'
  | 'tablet'
  | 'desktop'
  | 'large-desktop';

/**
 * Sort an array of objects by the position key
 * @param array - array of objects with a position key as it comes from Zapp API
 * @returns - sorted array of objects where it mutates the array and returns a reference to the same array.
 */
export function sortByPositionKey(array: any): any[] {
  if (!Array.isArray(array)) return [];

  return array.sort((a, b) => {
    if (typeof a.position !== 'number' || typeof b.position !== 'number')
      return 0;

    return a.position - b.position;
  });
}

export function getIsLastComponentInScreen(
  screenUiComponents: UIComponent[],
  uiComponentId: string
): boolean {
  try {
    const lastComponent: UIComponent | undefined = screenUiComponents?.at(-1);

    if (!lastComponent) return false;

    if (lastComponent?.id === uiComponentId) return true;

    if (lastComponent?.component_type === 'group-qb') {
      return getIsLastComponentInScreen(
        lastComponent?.ui_components,
        uiComponentId
      );
    }

    return false;
  } catch (error: any) {
    logger.error(`isLastComponentInScreen: ${error.message}`);
    return false;
  }
}

export function getUniqueKeyByEntry(
  prefix: string,
  entry: FeedEntry,
  index: number
): string {
  const key: string | undefined = entry?._key || entry?.id;
  if (!key) return `${prefix}-${index}`;
  return `${prefix}-${key}-${index}`;
}

export function generateSkeletonEntries(length = 10) {
  return Array.from({ length }).map((_, i) => {
    const key: string = `skeleton-${i}-${nanoid(6)}`;
    return {
      _key: key,
      id: key,
      _isLoadingSkeletonEntry: true,
    } as unknown as FeedEntry;
  });
}

export function filterSkeletonEntries(entries: FeedEntry[]) {
  if (!Array.isArray(entries)) return entries;
  return entries.filter((entry) => !entry?._isLoadingSkeletonEntry);
}

export function filterFeedEntriesByUniqueId(feedEntries: FeedEntry[]) {
  try {
    const seenIds = new Set();
    return feedEntries.filter((entry) => {
      if (seenIds.has(entry.id)) return false;

      seenIds.add(entry.id);

      return true;
    });
  } catch (error) {
    return feedEntries;
  }
}

export function getCSSNumber(element: any, property: string): number {
  try {
    const propertyValue = getComputedStyle(element).getPropertyValue(property);
    const propertyNumberValue = propertyValue.match(/\d+/);
    return propertyNumberValue ? parseFloat(propertyNumberValue[0]) : 0;
  } catch (error) {
    return 0;
  }
}

export type ResponsiveCellIds = {
  mobileCellId: string | undefined;
  tabletCellId: string | undefined;
  desktopCellId: string | undefined;
  largeDesktopCellId: string | undefined;
};

export function getUiComponentResponsiveCellIds(
  uiComponent: UIComponent
): ResponsiveCellIds {
  return {
    mobileCellId: uiComponent?.styles?.mobile_cell_plugin_configuration_id,
    tabletCellId: uiComponent?.styles?.tablet_cell_plugin_configuration_id,
    desktopCellId: uiComponent?.styles?.desktop_cell_plugin_configuration_id,
    largeDesktopCellId:
      uiComponent?.styles?.large_desktop_cell_plugin_configuration_id,
  };
}

/**
 * Convert and parse a string that is formatted correctly or incorrectly.
 * the input string can be formatted as valid or invalid string[].
 * @param envVarStr
 * @returns parsed string array
 * @example1
 * convertAndParseStringArray('[val1,val2,val3]')
 * returns ["val1", "val2", "val3"]
 * @example2
 * convertAndParseStringArray(`["val1","val2","val3"]`)
 * returns ["val1", "val2", "val3"]
 */
export function convertAndParseStringArray(envVarStr: string): string[] {
  try {
    if (typeof envVarStr !== 'string')
      throw new Error('string array is not a string');

    const performJsonParse: string[] | string = getJsonParse(envVarStr);

    if (performJsonParse !== 'parseError' && Array.isArray(performJsonParse))
      return performJsonParse;

    const checkString: boolean =
      envVarStr?.startsWith('[') &&
      envVarStr?.endsWith(']') &&
      envVarStr?.length > 2;

    if (!checkString) throw new Error('Invalid string array');

    const fontFileNames: string[] = envVarStr.slice(1, -1).split(',');

    const result = fontFileNames.map(
      (filename: string) => `"${filename.trim()}"`
    );

    const parsedResult: string = `[${result.join(',')}]`;

    const parsedArray: string[] = JSON.parse(parsedResult);

    return parsedArray;

    function getJsonParse(str: any): string[] | string {
      try {
        return JSON.parse(str);
      } catch (error) {
        return 'parseError';
      }
    }
  } catch (error: any) {
    logger.info(`convertAndParseStringArray: ${error.message}`);
    return [];
  }
}

export function isEmptyArray(array: any[] | undefined): boolean {
  return Array.isArray(array) && array?.length === 0;
}
